@mixin base-card {
  @apply p-20;
  @apply flex;
  @apply flex-col;
  @apply border-1;
  @apply rounded;
}

@mixin exclusive-card {
  .bg-img {
    transition: transform 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  &:hover {
    .bg-img {
      transform: scale(105%);
    }
  }
}

@mixin session-card {
  .text-wrapper {
    transition: background-color 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
    .text {
      transition: opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
    .text-overlay,
    .interaction-overlay {
      transition: opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
    button {
      span {
        @apply text-body-xxs;
      }
    }
  }
}

@mixin resource-card {
  transition: background-color 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
  .resource-m {
    .main-text,
    .alt-text {
      transition: opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
  }

  .link-wrapper {
    transition: color 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
    svg {
      path {
        transition: fill 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
      }
    }
  }

  &.hovered {
    background-color: rgba(229, 229, 229, 0.5);
    .link-wrapper {
      color: $rubine;
      transition: color 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
      svg {
        path {
          fill: $rubine;
          transition: fill 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
        }
      }
    }
  }
}

.component-card-anatomy {
  @include base-card();
  &.resource-card {
    @include resource-card();
  }

  &:not(.no-action) {
    @apply cursor-pointer;
    @include lift-on-hover;

    &:focus-visible {
      @apply shadow-button-focus;
    }
  }

  &.session-card {
    @include session-card();
  }

  &.exclusive-card {
    @include exclusive-card();
  }
}

.component-card-empty {
  @include base-card();
  @apply border-dashed;
}

.sentence-case {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
