$font-weight--normal: 400;
$font-weight--regular: 500;
$font-weight--semi-bold: 600;
$font-weight--bold: 700;

// Type Weights
$font-weight-h1: $font-weight--bold;
$font-weight-h2: $font-weight--bold;
$font-weight-h3: $font-weight--bold;
$font-weight-h4: $font-weight--bold;
$font-weight-h5: $font-weight--bold;

$font-weight-t1: $font-weight--normal;
$font-weight-t2: $font-weight--normal;
$font-weight-t3: $font-weight--semi-bold;
$font-weight-t4: $font-weight--normal;
$font-weight-t5: $font-weight--semi-bold;
$font-weight-t6: $font-weight--normal;
$font-weight-t7: $font-weight--semi-bold;
$font-weight-t8: $font-weight--bold;
