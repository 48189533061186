// vertical offset rows
// useful for adding vertical space between elements/rows
.voffset-0 {
  margin-top: 0 !important;
}
.voffset-1 {
  margin-top: 15px !important;
}
.voffset-2 {
  margin-top: 30px !important;
}
.voffset-3 {
  margin-top: 45px !important;
}
.voffset-4 {
  margin-top: 60px !important;
}
.voffset-5 {
  margin-top: 75px !important;
}
.voffset-6 {
  margin-top: 90px !important;
}
.voffset-7 {
  margin-top: 115px !important;
}
.voffset-8 {
  margin-top: 130px !important;
}
