.flash-messages-container {
  left: 50%; // removing this will break interactive elements behind the flash container
  transform: translateX(
    -50%
  ); // removing this will break interactive elements behind the flash container

  .flash-message-toast {
    &.active {
      transform: translateY(0%);
    }
    &.alert {
      position: relative;
    }
  }
}
