@import 'utils/calculate-rem';
@import 'mixins/browser';
@import 'mixins/interactions';
@import 'mixins/responsive';
@import 'variables/all';
@import 'reset';
@import 'typography/all';
@import 'layout';
@import 'grid';
@import 'alerts';
@import 'print';
@import 'components/all';

@tailwind base;
@tailwind utilities;