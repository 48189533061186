@import './colors';

@keyframes fade-in-linear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out-linear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 0;
    transform: scale(0.5);
  }
  95% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shrink-milestone-checkbox {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes expand-milestone-checkbox {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shrink {
  0% {
    height: 307px;
  }
  33% {
    height: 307px;
  }
  100% {
    height: 101px;
  }
}

@keyframes wpa-star-follow-path {
  0% {
    margin-left: 0;
  }
  33% {
    margin-left: 0;
    transform: rotate(0deg);
  }
  65% {
    margin-left: 25px;
  }
  70% {
    margin-left: 0;
  }
  75% {
    margin-left: -25px;
  }
  100% {
    margin-left: 0;
    transform: rotate(360deg);
  }
}

@keyframes delayed-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes in-progress-navbar-coloring {
  0%,
  100% {
    background: $rubine;
  }
  50% {
    background: lighten($rubine, 10%);
  }
}

// button color pulled from session-countdown-navbar
$countdownButtonColor: rgba($navy, 0.55);
@keyframes in-progress-join-coloring {
  0%,
  100% {
    background-color: $countdownButtonColor;
  }
  50% {
    background-color: lighten($countdownButtonColor, 15%);
  }
}

@keyframes spin {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spin-forwards {
  to {
    transform: rotate(360deg);
  }
}

@keyframes quick-fade-in-slow-fade-out {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes wave {
  to {
    transform: translate(-50%, 0);
  }
}
