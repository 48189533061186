// ============================================================================
// Typography
// ============================================================================

.heading-xxl {
  @apply font-ivar;
  @apply text-heading-xxl-mobile;
  font-weight: $font-weight--bold;

  @include desktop {
    @apply text-heading-xxl-desktop;
  }
}

.heading-xl {
  @apply font-ivar;
  @apply text-heading-xl-mobile;
  font-weight: $font-weight--bold;

  @include desktop {
    @apply text-heading-xl-desktop;
  }
}

.heading-l {
  @apply font-ivar;
  @apply text-heading-l-mobile;
  font-weight: $font-weight--bold;

  @include desktop {
    @apply text-heading-l-desktop;
  }
}

.heading-m {
  @apply font-ivar;
  @apply text-heading-m;
  font-weight: $font-weight--semi-bold;
}

.heading-s {
  @apply font-soehne;
  @apply text-heading-s;
  font-weight: $font-weight--semi-bold;
}

.heading-xs {
  @apply font-soehne;
  @apply text-heading-xs;
  font-weight: $font-weight--semi-bold;
}

.body-caps {
  @apply font-semibold;
  @apply font-soehne;
  @apply text-body-caps;
  @apply text-body;

  // Uppercase doesn't work in all locales. Safe for English, though.
  &:lang(en) {
    text-transform: uppercase;
    /* stylelint-disable-line property-disallowed-list */
  }
}

.body-l {
  @apply font-soehne;
  @apply text-body-l;
  font-weight: $font-weight--normal;
}

.body-m {
  @apply font-soehne;
  @apply text-body-m;
  font-weight: $font-weight--normal;
}

.body-s {
  @apply font-soehne;
  @apply text-body-s;
  font-weight: $font-weight--normal;
}

.body-xs {
  @apply font-soehne;
  @apply text-body-xs;
  font-weight: $font-weight--normal;
}

.body-xxs {
  @apply font-soehne;
  @apply text-body-xxs;
  font-weight: $font-weight--normal;
}

.data-viz-s {
  @apply font-soehne;
  font-size: calculateRem(20);
  line-height: calculateRem(20);
  letter-spacing: calculateRem(0.2);
}

// ===========================
// POST-ASSESSMENT FONT STYLES
// ===========================
// Figma: 'Headline Extra Large'
.pa-heading-xl {
  @apply font-ivar;
  @apply text-pa-heading-xl;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Headline Large'
.pa-heading-l {
  @apply font-soehne;
  @apply text-pa-heading-l;
  font-weight: $font-weight--regular; // 500
}

// Figma: 'Headline Medium'
.pa-heading-m {
  @apply font-soehne-breit;
  @apply text-pa-heading-m;
  font-weight: $font-weight--regular; // 500
}

// Figma: 'Headline Medium Bold'
.pa-heading-mb {
  @apply font-soehne-breit;
  @apply text-pa-heading-m;
  font-weight: $font-weight--semi-bold; // 600
}

// Figma: 'Headline Small'
.pa-heading-s {
  @apply font-soehne;
  @apply text-pa-heading-s;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Headline Small'
.pa-heading-xs {
  @apply font-soehne;
  @apply text-pa-heading-xs;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Stat Headline'
.pa-heading-stat {
  @apply font-soehne-breit;
  @apply text-pa-heading-xs;
  font-weight: $font-weight--semi-bold; // 600
}

// Figma: 'Body Normal large'
.pa-body-xl {
  @apply font-soehne;
  @apply text-pa-body-xl;
  font-feature-settings: 'liga' 0;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Body Normal'
.pa-body {
  @apply font-soehne;
  @apply text-pa-body;
  font-feature-settings: 'liga' 0;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Body Small'
.pa-body-s {
  @apply font-soehne;
  @apply text-pa-body-s;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Body Small Bold'
.pa-body-sb {
  @apply font-soehne;
  @apply text-pa-body-s;
  font-weight: $font-weight--semi-bold; // 600
}

// Figma: 'Label (active)'
.pa-body-label-active {
  @apply font-soehne;
  @apply text-pa-body-xs;
  font-weight: $font-weight--semi-bold; // 600
}

// Figma: 'Label (inactive)'
.pa-body-label-inactive {
  @apply font-soehne;
  @apply text-pa-body-xxs;
  font-weight: $font-weight--normal; // 400
}

.pa-body-xxxs {
  @apply font-soehne;
  @apply text-pa-body-xxxs;
  font-weight: $font-weight--normal; // 400
}

// Figma: 'Coach Tip Large'
.pa-body-coach-tip-l {
  @apply font-soehne;
  @apply text-pa-body-l;
  font-weight: $font-weight--semi-bold; // 600
}

// Figma: 'Coach Tip Small'
.pa-body-coach-tip-s {
  @apply font-soehne;
  @apply text-pa-body-s;
  font-weight: $font-weight--semi-bold; // 00
}

.card-heading {
  @apply font-soehne-breit;
  @apply text-card-heading;
  font-weight: $font-weight--semi-bold; // 600
}

// Login
.required {
  font-weight: bold;
}

.required:after {
  content: ' *';
  display: inline;
}

// These styles were copied from the Teams UI Library
// https://63587347138fdad13ed63ccd-omfbjvvebn.chromatic.com/
.heading-msteams {
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
}

.body-msteams {
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
  font-size: 0.875rem;
}
