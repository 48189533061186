* {
  border-color: $gray-10;
  border-style: solid;
  border-width: 0;
}

// Set default focus styles
*:focus {
  // border: 1px solid #fff;
  outline: none; // prevent outline flicker
}

*:focus-visible {
  @apply outline-none; // This applies a transparent outline which is necessary for Windows high contrast mode
  @apply shadow-current-color-focus;
}

a:focus {
  @apply outline-none; // Override Bootstrap
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
