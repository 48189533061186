@import 'react-h5-audio-player/src/styles.scss';

.rhap_container {
  @apply shadow-none;
  @apply bg-transparent;
  .rhap_progress-indicator {
    @apply bg-midnight;
  }
  .rhap_progress-filled {
    @apply bg-gray-aa;
  }
  .rhap_main-controls-button {
    @apply text-rubine;
  }
}

.audio-player-inverted {
  .rhap_progress-indicator {
    @apply bg-white;
  }
  .rhap_controls-section {
    @apply text-inverted;
    .rhap_current-time {
      @apply text-inverted;
    }
    .rhap_total-time {
      @apply text-inverted;
    }
  }
}

.donut {
  .donut-ring {
    stroke: $data-base;
  }

  .donut-segment {
    stroke: $data-series-one;
    transform-origin: center;
    transform: rotate(270deg);
  }

  .donut-series-1 {
    stroke: $data-series-one;
  }

  .donut-series-2 {
    stroke: $data-series-two;
  }

  .donut-series-3 {
    stroke: $data-series-three;
  }

  .donut-series-4 {
    stroke: $data-series-four;
  }

  .donut-series-5 {
    stroke: $data-series-five;
  }

  .donut-series-6 {
    stroke: $data-series-six;
  }

  .donut-percent {
    font-size: 0.4em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
  }

  .donut-text {
    fill: $data-text;
  }

  .donut-text-inverted {
    fill: $data-text-inverted;
  }
}

.line-after {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    margin-left: 10px;
    border-top: 1px solid $midnight;
    opacity: 0.5;
  }
}

.line-after-inverted {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    margin-left: 10px;
    border-top: 1px solid $white;
    opacity: 0.5;
  }
}

.practice-text {
  b,
  strong {
    font-weight: 600;
    color: #fff;
  }
}

.practice-text-inverted {
  b,
  strong {
    font-weight: 600;
    color: #000;
  }
}

.notice {
  color: $rubine;
  border: 1px solid $rubine;
}
