// Effect Class: "fade"
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 600ms;
}

// Effect Classname: "cue-delay-fade"
.cue-delay-fade-enter {
  opacity: 0;
}

.cue-delay-fade-enter-active {
  opacity: 1;
  transition: opacity 600ms 3400ms;
}

.cue-delay-fade-exit {
  opacity: 1;
}

.cue-delay-fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

// Effect Classname: "check-delay-fade" (only has a fade-out)
.check-delay-fade-enter {
  opacity: 0;
}

.check-delay-fade-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}

.check-delay-fade-exit {
  opacity: 1;
}

.check-delay-fade-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

// Effect Classname: "cue-practice"
.cue-practice-enter {
  opacity: 0;
}

.cue-practice-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

@include mobile {
  .cue-practice-enter-active {
    animation: 4s cubic-bezier(0.34, 1.56, 0.64, 1) 1 scale-step,
      4s cubic-bezier(0.34, 1.56, 0.64, 1) 1 position-step-mobile;
  }
}

@include tablet-and-up {
  .cue-practice-enter-active {
    animation: 4s cubic-bezier(0.34, 1.56, 0.64, 1) 1 scale-step,
      4s cubic-bezier(0.34, 1.56, 0.64, 1) 1 position-step-desktop;
  }
}

@keyframes scale-step {
  0% {
    scale: 0.75;
  }

  25%,
  75% {
    scale: 1.2;
  }

  100% {
    scale: 1;
  }
}

@keyframes position-step-desktop {
  0%,
  75% {
    position: relative;
    left: 412px;
  }

  100% {
    position: relative;
    left: 0;
  }
}

@keyframes position-step-mobile {
  0%,
  75% {
    position: relative;
    left: 10;
  }

  100% {
    position: relative;
    left: 10;
  }
}
