@mixin lift-on-hover() {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  &:hover {
    @apply shadow-card;
    transform: translate(0, -5px);
  }
}

.lift-on-hover {
  @include lift-on-hover();
}
