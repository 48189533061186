@mixin desktop {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: theme('screens.mobile.max')) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media (max-width: theme('screens.tablet.min')) {
    @content;
  }
}

@mixin tablet-and-up {
  @media (min-width: theme('screens.tablet.min')) {
    @content;
  }
}