/* stylelint-disable color-no-hex */

// Brand Colors

// Background colors
$background: #fff;

// Primary colors

// Primary brand color
$rubine: #ce0058;

// Neutrals
$midnight: #1d1925;
$white: #fff;

// Blues
$blue-90: #002033;
$blue-85: #110e52;
$blue-80: #004066;
$blue-70: #006099;
$blue-60: #0080cc;
$blue-50: #009fff;
$blue-40: #006099;
$blue: $blue-40;
$blue-30: #66c6ff;
$blue-20: #99d9ff;
$blue-10: #ccecff;
$blue-05: #e5f5ff;

// Grays
$gray-90: #1a1a1a;
$gray-80: #333333;
$gray-70: #4d4d4d;
$gray-50: #757575;
$gray-30: #b2b2b2;
$gray-10: #e5e5e5;
$gray-01: #f2f2f2;
$gray-aa: #767676;
$dawn: #2e2836;

// Accent Colors

// Corals
$coral-90: #330400;
$coral-80: #660900;
$coral-70: #990d00;
$coral-40: #ff5b4c;
$coral-10: #ffd0cc;
$coral-05: #ffe8e5;

// Teals
$teal-70: #00804e;
$teal-50: #00e58d;
$teal-40: #1affa6;
$teal-10: #1affa6;
$teal-05: #e5fff5;

// Yellows
$yellow-70: #8f6f00;
$yellow-40: #ffc700;
$yellow-10: #fff4cc;
$yellow-05: #fff9e5;

// Purple
$purple-90: #210033;
$purple-80: #31004d;
$purple-75: #110e52;
$purple-70: #520080;
$purple-60: #7300b2;
$purple-50: #9400e5;
$purple-40: #b123ff;
$purple: $purple-40;
$purple-30: #c152ff;
$purple-20: #d280ff;
$purple-10: #e4b3ff;
$purple-05: #f6e5ff;

// Legacy colors
$navy: #242963;
$green: #85c240;
$greenHover: darken($green, 5%);

// Red used for assessment inputs
$red: #ff6a4c;

// Typography colors
$text-color-link: $blue-90;
$text-color-danger: $coral-70;

// Typography colors, legacy
$text-color: $gray-90;
$text-muted-color: $gray-70;

// Alerts
$alert-success: $teal-40;
$alert-warn: $yellow-40;
$alert-error: $coral-40;

// Borders
$border-cta-hover: #f5f5f5;
$border-color: $gray-10;
$border-color-layer-2: $gray-10;
$messages-badge-color: $rubine;

// Data Visualizations
$data-base: lighten($gray-70, 60%);
$data-text: $gray-70;
$data-text-inverted: $white;
$data-series-one: theme('colors.data-series-one');
$data-series-two: theme('colors.data-series-two');
$data-series-three: theme('colors.data-series-three');
$data-series-four: theme('colors.data-series-four');
$data-series-five: theme('colors.data-series-five');
$data-series-six: theme('colors.data-series-six');
