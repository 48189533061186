//Print Styles
*,
*::before,
*::after {
  @media print {
    -webkit-print-color-adjust: exact;
    font-size: calculateRem(16);
  }
}

.avoid-break-inside {
  break-inside: avoid;
}
