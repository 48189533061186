#app {
  .button-primary,
  .button-primary-inverted,
  .button-secondary,
  .button-secondary-inverted {
    @apply text-body-m;
    @apply border;
    @apply px-button-x;
    @apply py-button-y;
    @apply rounded-100;
    @apply no-underline;
    @apply font-normal;

    &:focus-visible {
      @apply outline-none;
    }

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply border-disabled;
      @apply text-disabled;
    }
  }

  .button-primary,
  .button-secondary {
    &:focus-visible {
      @apply shadow-button-focus;
      @apply border-button-focus;
    }
  }

  .button-primary {
    @apply bg-button-primary;
    @apply border-button-primary;
    @apply text-inverted;

    &:hover {
      @apply bg-button-primary-hover;
      @apply border-button-primary-hover;
    }

    &:active {
      @apply bg-button-primary-active;
      @apply border-button-primary-active;
    }

    // &:focus {
    //   border: #fff 1px solid;
    // }

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply bg-disabled;
    }
  }

  .button-primary-inverted {
    @apply bg-button-primary-inverted;
    @apply border-button-primary-inverted;
    // @apply text-inverted;

    &:hover {
      @apply bg-button-primary-inverted-hover;
      @apply border-button-primary-inverted-hover;
    }

    &:active {
      @apply bg-button-primary-inverted-active;
      @apply border-button-primary-inverted-active;
    }

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply bg-disabled;
    }
  }

  .button-secondary {
    @apply bg-transparent;
    color: white;
    border: 1px white solid;

    &:hover {
      @apply bg-cta-hover;
    }

    &:active {
      @apply bg-cta-active;
    }

    &:focus-visible {
      @apply bg-transparent;
    }
  }

  .button-secondary-inverted {
    @apply bg-transparent;
    @apply border-cta-inverted;
    @apply text-inverted;

    &:hover {
      @apply bg-cta-hover-inverted;
    }

    &:active {
      @apply bg-cta-active-inverted;
    }

    &:focus-visible {
      @apply bg-transparent;
      @apply shadow-button-focus-inverted;
      @apply border-button-focus-inverted;
    }
  }

  $button-types: 'tertiary', 'inverted-tertiary';

  $link-types: 'link', 'muted', 'muted-link';

  $inverted-button-types: 'inverted-tertiary';

  @each $button-or-link-type in join($button-types, $link-types) {
    .button-#{$button-or-link-type} {
      @apply text-body-s;
      @apply no-underline;
      @apply font-normal;

      @apply bg-button-#{$button-or-link-type};
      @apply text-button-#{$button-or-link-type};

      &:hover {
        @apply text-button-#{$button-or-link-type}-hover;
      }

      &:active {
        @apply text-button-#{$button-or-link-type}-active;
      }

      &:focus,
      &:active,
      &:active:focus,
      &:active:hover {
        outline: none;
      }

      &:active,
      &:active:focus,
      &:active:hover {
        @apply shadow-none;
      }
    }
  }

  @each $type in $button-types {
    .button-#{$type} {
      @apply rounded-button;
      @apply border-button;
      @apply border-button-#{$type};
      @apply border-solid;

      &:hover {
        @apply bg-button-#{$type}-hover;
        @apply border-button-#{$type}-hover;
      }

      &:active {
        @apply bg-button-#{$type}-active;
        @apply border-button-#{$type}-active;
      }

      &:focus-visible {
        @apply outline-none;
        @apply shadow-button-focus;
      }

      &[disabled],
      &[disabled]:hover,
      &[aria-disabled='true'],
      &[aria-disabled='true']:hover {
        @apply bg-button-disabled;
        @apply border-button-disabled;
        @apply text-button-disabled;
      }
    }
  }

  @each $type in $link-types {
    .button-#{$type} {
      @apply font-semibold;

      &:focus-visible {
        @apply outline-none;
        @apply shadow-current-color-focus;
      }

      &[disabled],
      &[disabled]:hover,
      &[aria-disabled='true'],
      &[aria-disabled='true']:hover {
        @apply bg-button-link-disabled;
        @apply text-button-link-disabled;
      }
    }
  }

  @each $type in $inverted-button-types {
    .button-#{$type} {
      &:focus-visible {
        @apply outline-none;
        @apply shadow-button-focus-inverted;
      }
    }
  }

  // Todo: clean up _buttons stylesheet https://betterup.atlassian.net/browse/BUAPP-31892
  .button-tertiary,
  .button-inverted-tertiary {
    @apply font-medium;
    @apply bg-button-tertiary;
    @apply rounded-button-tertiary;
    @apply p-0;
  }

  .button-tertiary {
    &:active,
    &:active:focus,
    &:active:hover {
      @apply shadow-button-tertiary-active;
      @apply bg-button-tertiary-active;
    }

    &:hover:not([disabled]):not([aria-disabled='true']) {
      @apply shadow-button-tertiary-hover;
      @apply bg-button-tertiary-hover;
    }

    &:focus-visible {
      @apply shadow-button-focus;
    }

    &[disabled],
    &[aria-disabled='true'] {
      @apply text-disabled;
      @apply bg-button-tertiary;
      @apply border-transparent;

      &:active,
      &:hover {
        @apply text-disabled;
        @apply bg-button-tertiary;
        @apply border-transparent;
        @apply shadow-none;
      }
    }
  }

  .button-inverted-tertiary {
    &:active,
    &:active:focus,
    &:active:hover {
      @apply shadow-button-inverted-tertiary-active;
      @apply bg-button-inverted-tertiary-active;
    }

    &:hover {
      @apply shadow-button-inverted-tertiary-hover;
      @apply bg-button-inverted-tertiary-hover;
    }

    &:focus-visible {
      @apply shadow-current-color-focus;
    }
  }

  .button-text,
  .button-inverted-text {
    @apply text-body-s;
    @apply font-normal;
    @apply bg-button-text;
    @apply rounded-button-text;
    @apply p-0;
    @apply underline;
  }

  .button-text {
    &:hover {
      @apply bg-button-text-hover;
      @apply shadow-button-text-hover;
    }

    &:active,
    &:active:focus,
    &:active:hover {
      @apply bg-button-text-active;
      @apply shadow-button-text-active;
    }

    &:focus-visible {
      @apply shadow-current-color-focus;
    }

    &[disabled],
    &[aria-disabled='true'] {
      @apply text-disabled;
      @apply bg-button-text;
      @apply border-transparent;

      &:hover {
        @apply text-disabled;
        @apply bg-button-text;
        @apply border-transparent;
        @apply shadow-none;
      }
    }
  }

  .button-inverted-text {
    @apply text-inverted;

    &:active,
    &:active:focus,
    &:active:hover {
      @apply shadow-button-inverted-text-active;
      @apply bg-button-inverted-text-active;
    }

    &:hover {
      @apply shadow-button-inverted-tertiary-hover;
      @apply bg-button-inverted-tertiary-hover;
    }

    &:focus-visible {
      @apply shadow-current-color-focus;
    }
  }

  .button-social {
    @apply bg-button-social;
    @apply rounded-button-social;
    @apply p-8;
    @apply text-button-social;

    &:active,
    &:active:focus,
    &:active:hover {
      @apply bg-button-social-active;
    }

    &:hover {
      @apply bg-button-social-hover;
    }

    &:focus-visible {
      @apply shadow-button-focus;
    }

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply border-disabled;
      @apply bg-disabled;
      @apply text-button-disabled;
    }
  }

  .button-icon,
  %button-icon {
    @apply bg-transparent;
    @apply text-midnight;
    @apply rounded-full;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;

    &:hover {
      @apply bg-cta-hover;
    }

    &:active,
    &:active:focus,
    &:active:hover {
      @apply bg-cta-active;
    }

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply text-gray-aa;
    }
  }

  .button-icon-outline {
    @extend %button-icon;
    @apply border-1;
    @apply border-midnight;

    &[disabled],
    &[disabled]:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:hover {
      @apply text-gray-aa;
      @apply border-gray-aa;
    }
  }

  .button-icon-inverted {
    @extend %button-icon;
    @apply text-white;

    &:hover {
      @apply bg-cta-hover-inverted;
    }

    &:active,
    &:active:focus,
    &:active:hover {
      @apply bg-cta-active-inverted;
    }

    &.button-icon-outline {
      @apply border-1;
      @apply border-white;

      &[disabled],
      &[disabled]:hover,
      &[aria-disabled='true'],
      &[aria-disabled='true']:hover {
        @apply text-gray-aa;
        @apply border-gray-aa;
      }
    }

    &.selected {
      @apply bg-white;
      @apply text-dawn;
    }
  }
}
