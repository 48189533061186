$fontResourcePath: '../../assets/fonts/';

// ============================================================================
// Import Soehne and Soehne Breit
// ============================================================================
@font-face {
  font-family: 'Söehne'; // Normal 400
  src: url('#{$fontResourcePath}soehne/soehne-web-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Söehne'; // Medium 500
  src: url('#{$fontResourcePath}soehne/soehne-web-kraftig.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Söehne'; // Semibold 600
  src: url('#{$fontResourcePath}soehne/soehne-web-halbfett.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Söehne Breit'; // Normal 400
  src: url('#{$fontResourcePath}soehne-breit/soehne-breit-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Söehne Breit'; // Semibold 600
  src: url('#{$fontResourcePath}soehne-breit/soehne-breit-halbfett.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

// ============================================================================
// Import Ivar Headline
/* Webfonts: Copyright © Letters From Sweden.*/
// ============================================================================
@font-face {
  font-family: 'Ivar Headline'; // Normal 400
  src: url('#{$fontResourcePath}ivar-headline/IvarHeadline-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
