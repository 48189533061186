// Structure
body {
  margin: 0;
  overflow-x: hidden;

  @media print {
    overflow-x: visible;
  }
}

html,
body {
  height: 100%;
  background-color: $background;
}

.content-container {
  padding-top: 100px;
  padding-bottom: 40px;
  @include mobile {
    padding-top: 65px;
  }
}

.with-countdown-navbar .content-container {
  padding-top: 125px;
  @include mobile {
    padding-top: 102px;
  }
}
.user-content {
  .content-container {
    @include mobile {
      padding-top: 90px;
    }
  }
  @media print {
    margin: 0;
    width: 100%;
  }
}

// Secondary Navbar
.profile-header {
  font-size: calculateRem(16);
  padding: 30px 0 15px;
  overflow: hidden;
}

//offset for banner and sidebar height in client hub
.user-content .loading-notice .spinner {
  margin-top: -200px;
  @include tablet-and-up {
    margin-left: 50%;
  }
}

.explore-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -40px;
  margin-left: -100px;

  @include mobile-and-tablet {
    width: 100%;
    margin: -40px auto;
  }

  .spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;
    display: block;
  }
}

// error pages
.content-wrapper-error {
  margin-top: 160px;

  h1 {
    margin-bottom: 6px;
  }
}
